// StatusIcon.tsx

import React from "react";
import {
  RocketLaunch,
  MoreHoriz,
  PauseCircleFilledSharp,
  AssignmentTurnedIn,
  Cancel,
  QueryStats,
  PhoneDisabled,
} from "@mui/icons-material";
import { pink } from "@mui/material/colors";

export function StatusIcon(status: string) {
  switch (status) {
    case "Launched":
      return (
        <RocketLaunch
          className={"weblab-status"}
          sx={{ color: "green", fontSize: "inherit" }}
        />
      );
    case "In Progress":
    case "In Progress-Coding":
    case "In Progress-Dialed Up":
    case "In Progress-Engineering Deployment":
      return (
        <MoreHoriz
          className={"weblab-status"}
          sx={{ color: "black", fontSize: "inherit" }}
        />
      );
    case "Paused":
      return (
        <PauseCircleFilledSharp
          className={"weblab-status"}
          sx={{ color: "red", fontSize: "inherit" }}
        />
      );
    case "Planned":
      return (
        <AssignmentTurnedIn
          className={"weblab-status"}
          sx={{ color: "blue", fontSize: "inherit" }}
        />
      );
    case "Not Dialed Up":
      return (
        <PhoneDisabled
          className={"weblab-status"}
          sx={{ color: pink[500], fontSize: "inherit" }}
        />
      );
    case "Not Launched":
      return (
        <Cancel
          className={"weblab-status"}
          sx={{ color: pink[500], fontSize: "inherit" }}
        />
      );
    case "Finalized: Analysis":
      return (
        <QueryStats
          className={"weblab-status"}
          sx={{ color: "green", fontSize: "inherit" }}
        />
      );
    default:
      return <></>;
  }
}

// @ts-ignore
export const StatusCellRenderer = ({ value }) => {
  return (
    <div>
      {StatusIcon(value)}
      {value}
    </div>
  );
};
