import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { getAmplifyConfig } from "./amplifyConfig";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

const callbackURL = window.location.origin;
const configObj = getAmplifyConfig(callbackURL);
if (configObj) {
  // @ts-ignore
  configObj.API.graphql_headers = async () => ({
    Authorization: (await fetchAuthSession()).tokens?.accessToken,
    AuthUser: (await getCurrentUser()).username,
  });
}
Amplify.configure(configObj);
console.log("Amplify configured with dynamic config");
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
