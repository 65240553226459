/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { type Team } from "../models/API";
import { Resizable } from "re-resizable";
import { Box, Link, Typography } from "@mui/material";

interface CohortAnalysisComponentProps {
  isOpen: boolean;
  currentUserName: string;
  currentTeamId: number;
  teamConfig: Team;
}

const CohortAnalysisComponent: React.FC<CohortAnalysisComponentProps> = ({
  isOpen,
  currentUserName,
  currentTeamId,
  teamConfig,
}) => {
  if (!isOpen) {
    return null;
  }
  if (currentTeamId === 0)
    return (
      isOpen && (
        <div>
          <div>You don't have permission to access any team's data.</div>
        </div>
      )
    );
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "87.8%",
      }}
    >
      <Resizable>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            {
              "The SPB Finance Data Engineering Team is migrating the Cohort Analysis submenu. For your convenience, you can find the Tableau version dashboard "
            }
            <b>
              <Link
                href={
                  "https://sspa-tableau.aka.amazon.com/#/views/WeblabTracking/WeblabCohortAnalysisTracking?:iid=1"
                }
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                here.
              </Link>
            </b>
          </Typography>
        </Box>
      </Resizable>
    </div>
  );
};

export default CohortAnalysisComponent;
