import React, { useState, useEffect, useCallback, useRef } from "react";
import { marked } from "marked";
import { Typography } from "@mui/material";
import _debounce from "lodash/debounce";
import ErrorModal, { getErrorMessage } from "./ErrorModal";
import { generateClient } from "aws-amplify/api";
import { HelpContent } from "../models/API";
import { getHelpContent } from "../graphql/queries";

const HelpCenter = () => {
  const [error, setError] = useState<any>(null);
  const [filteredContent, setFilteredContent] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  let helpContent = useRef<string>("");
  const parser = new DOMParser();
  const client = generateClient();

  useEffect(() => {
    async function fetchContent() {
      try {
        const content = await getContent();
        // @ts-ignore
        const html = marked.parse(content);
        // @ts-ignore
        helpContent.current = html;
        // @ts-ignore
        setFilteredContent(html);
      } catch (err) {
        console.error(err);
        setError("Failed to load help content: " + getErrorMessage(err));
      }
    }
    fetchContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getContent() {
    let html = null;
    try {
      const result = await client.graphql({
        query: getHelpContent,
      });
      const content: HelpContent = result.data.getHelpContent;
      html = marked.parse(content.content);
    } catch (err) {
      console.error(err);
      setError("Failed to load help content: " + getErrorMessage(err));
    }
    return html;
  }

  function handleDebounceSearch(term: any) {
    if (!term) {
      setFilteredContent(helpContent.current);
      return;
    }
    let node;
    const nodes = [];
    const doc = parser.parseFromString(helpContent.current, "text/html");
    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT);
    while ((node = walker!.nextNode())) {
      nodes.push(node);
    }

    nodes.forEach((node) => {
      const text = node.textContent!;
      const parts = text.split(new RegExp(`(${term})`, "gi"));
      if (parts.length > 1) {
        const spanFragment = document.createDocumentFragment();
        parts.forEach((part: string, index: any) => {
          if (part.toLowerCase() === term.toLowerCase()) {
            const highlightSpan = document.createElement("span");
            highlightSpan.textContent = part;
            highlightSpan.style.backgroundColor = "yellow";
            spanFragment.appendChild(highlightSpan);
          } else {
            spanFragment.appendChild(document.createTextNode(part));
          }
        });
        node.parentNode!.replaceChild(spanFragment, node);
      }
    });
    setFilteredContent(doc!.body.innerHTML);
  }

  // eslint-disable-next-line
  const debounceSearch = useCallback(_debounce(handleDebounceSearch, 1000), []);

  function handleSearchChange(event: any) {
    setSearchTerm(event.target.value);
    debounceSearch(event.target.value);
  }

  return (
    <div className="help-center">
      <Typography
        variant="h5"
        gutterBottom
        style={{ textDecoration: "solid", color: "#0ABAB5" }}
      >
        Help Center
      </Typography>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="help-search-box"
      />
      <div dangerouslySetInnerHTML={{ __html: filteredContent }}></div>
      <ErrorModal error={error} onClose={() => setError(null)} />
    </div>
  );
};

export default HelpCenter;
