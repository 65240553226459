// FlagIcon.tsx

import React from "react";

export function FlagIcon(marketplace: string) {
  const marketplaceMap: Record<string, string> = {
    UK: "gb",
    KSA: "sa",
  };
  const countryCode =
    marketplace in marketplaceMap ? marketplaceMap[marketplace] : marketplace;
  return (
    <span
      className={
        "flag-icon flag-icon-" + countryCode.toLowerCase() + " flag-circle"
      }
    ></span>
  );
}

// @ts-ignore
export const FlagCellRenderer = ({ value }) => {
  return (
    <div>
      {FlagIcon(value)}
      {value}
    </div>
  );
};
