export const getAlphaConfig = (callbackURL: string) => {
  return {
    Auth: {
      Cognito: {
        userPoolClientId: "5180perke9gomnb8fd2ekf6p38",
        userPoolId: "us-east-1_bKDdIXR7H",
        loginWith: {
          // Optional
          oauth: {
            domain: "weblab-tracker2-alpha.auth.us-east-1.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: callbackURL,
            redirectSignOut: callbackURL,
            responseType: "code",
          },
          username: "true",
          email: "false", // Optional
          phone: "false", // Optional
        },
      },
    },
    oauth: {
      domain: "weblab-tracker2-alpha.auth.us-east-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: callbackURL,
      redirectSignOut: callbackURL,
      responseType: "code",
    },

    // Cognito options
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:c25f659f-c644-4c9e-a4ee-d0dd423c85a8",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_bKDdIXR7H",
    aws_user_pools_web_client_id: "5180perke9gomnb8fd2ekf6p38",
    // AppSync
    aws_appsync_graphqlEndpoint:
      "https://zbcldi7c2fhqdld2gi4pzlalq4.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    API: {
      graphql_endpoint:
        "https://axp63oywcnbzva55wzbjsnz6yu.appsync-api.us-east-1.amazonaws.com/graphql",
      graphql_headers: async () => ({ Authorization: "", AuthUser: "" }),
    },
  };
};
export const getGammaConfig = (callbackURL: string) => {
  return {
    Auth: {
      Cognito: {
        userPoolClientId: "4qr82hlqlopsjli99gosrqtlpp",
        userPoolId: "us-east-1_RrEf8imun",
        loginWith: {
          // Optional
          oauth: {
            domain: "weblab-tracker2-gamma.auth.us-east-1.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: callbackURL,
            redirectSignOut: callbackURL,
            responseType: "code",
          },
          username: "true",
          email: "false", // Optional
          phone: "false", // Optional
        },
      },
    },
    oauth: {
      domain: "weblab-tracker2-gamma.auth.us-east-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: callbackURL,
      redirectSignOut: callbackURL,
      responseType: "code",
    },
    // Cognito options
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:e5621057-213d-4259-b6d8-f53c24bc1e74",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_RrEf8imun",
    aws_user_pools_web_client_id: "4qr82hlqlopsjli99gosrqtlpp",
    // AppSync
    aws_appsync_graphqlEndpoint:
      "https://uegyz6js2zepxmyq2v4at7jvwq.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    API: {
      graphql_endpoint:
        "https://uegyz6js2zepxmyq2v4at7jvwq.appsync-api.us-east-1.amazonaws.com/graphql",
      graphql_headers: async () => ({ Authorization: "", AuthUser: "" }),
    },
  };
};
export const getProdConfig = (callbackURL: string) => {
  return {
    Auth: {
      Cognito: {
        userPoolClientId: "69nmb7ukvl5o40lc3k1pn5nfld",
        userPoolId: "us-east-1_LUwcBoU2L",
        loginWith: {
          // Optional
          oauth: {
            domain: "weblab-tracker2-prod.auth.us-east-1.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: callbackURL,
            redirectSignOut: callbackURL,
            responseType: "code",
          },
          username: "true",
          email: "false", // Optional
          phone: "false", // Optional
        },
      },
    },
    oauth: {
      domain: "weblab-tracker2-prod.auth.us-east-1.amazoncognito.com",
      scope: ["openid"],
      redirectSignIn: callbackURL,
      redirectSignOut: callbackURL,
      responseType: "code",
    },
    // Cognito options
    aws_project_region: "us-east-1",
    aws_cognito_identity_pool_id:
      "us-east-1:bc067a36-ef7f-46f7-9dad-55c1fed4dbe7",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_LUwcBoU2L",
    aws_user_pools_web_client_id: "69nmb7ukvl5o40lc3k1pn5nfld",
    // AppSync
    aws_appsync_graphqlEndpoint:
      "https://mu36hagzj5gsrh7x5pumjz2a44.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    API: {
      graphql_endpoint:
        "https://mu36hagzj5gsrh7x5pumjz2a44.appsync-api.us-east-1.amazonaws.com/graphql",
      graphql_headers: async () => ({ Authorization: "", AuthUser: "" }),
    },
  };
};

export const getAmplifyConfig = (url: string) => {
  if (url.includes("prod")) {
    return getProdConfig(url);
  }
  if (url.includes("gamma")) {
    return getGammaConfig(url);
  }
  return getAlphaConfig(url);
};
