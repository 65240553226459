import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

interface MessageModalProps {
  message: string | null;
}

const MessageModal: React.FC<MessageModalProps> = ({ message }) => {
  const open = Boolean(message);

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default MessageModal;
