/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { GetTeamsByUserNameInput, Team } from "../models/API";
import { getTeamsByUserName } from "../graphql/queries";

interface SwitchTeamComponentProps {
  onSwitch: (teamId: number, team: Team) => void;
  currentUserName: string;
  defaultTeamId: number;
}

const SwitchTeamComponent: React.FC<SwitchTeamComponentProps> = ({
  onSwitch,
  currentUserName,
  defaultTeamId,
}) => {
  const [teamId, setTeamId] = useState(defaultTeamId);
  const [teams, setTeams] = useState<Team[]>([]);
  const client = generateClient();

  const getTeams = async () => {
    try {
      const input: GetTeamsByUserNameInput = {
        user_name: currentUserName,
      };
      const result = await client.graphql({
        query: getTeamsByUserName,
        variables: {
          input: input,
        },
      });
      const teamsData = result.data.getTeamsByUserName;
      setTeams(teamsData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSwitch = (event: any) => {
    setTeamId(event.target.value);
    let team = teams.filter((item) => {
      return item.id === event.target.value;
    });
    onSwitch(event.target.value, team[0]);
  };

  useEffect(() => {
    setTeamId(defaultTeamId);
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTeamId]);

  return (
    <Grid>
      <FormControl fullWidth={true} margin="dense">
        <InputLabel
          id="team-select-label"
          style={{
            backgroundColor: "black",
            color: "orange",
            fontSize: "large",
          }}
        >
          Team
        </InputLabel>
        <Select
          value={teamId}
          label="Team"
          onChange={handleSwitch}
          style={{
            backgroundColor: "black",
            color: "white",
            fontSize: "xx-small",
          }}
        >
          {teams.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={{
                backgroundColor: "black",
                color: "orange",
              }}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default SwitchTeamComponent;
