import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  TextField,
} from "@mui/material";
import { Resizable } from "re-resizable";

import ads_logo from "../assets/amazon_ads_logo._TTW_.png";
import WeblabGridComponent from "./WeblabGridComponent";
import CohortAnalysisComponent from "./CohortAnalysisComponent";
import SwitchTeamComponent from "./SwitchTeamComponent";
import { Team } from "../models/API";

interface MainPageProps {
  currentUserName: string;
  defaultTeamId: number;
  defaultTeamConfig: Team;
}

const MainPage: React.FC<MainPageProps> = ({
  currentUserName,
  defaultTeamId,
  defaultTeamConfig,
}) => {
  const [activeItem, setActiveItem] = useState("User Input");
  const [teamId, setTeamId] = useState(defaultTeamId);
  const [teamConfig, setTeamConfig] = useState<Team>(defaultTeamConfig);

  function handleMenuItemClick(itemName: string) {
    setActiveItem(itemName);
  }

  const handleSwitchTeam = (teamId: number, team: Team) => {
    setTeamId(teamId);
    setTeamConfig(team);
  };

  useEffect(() => {
    if (teamId === 0) {
      setTeamId(defaultTeamId);
      setTeamConfig(defaultTeamConfig);
    }
  }, [teamId, defaultTeamId, defaultTeamConfig]);

  // @ts-ignore
  return (
    <div style={{ display: "flex", height: "100vh", width: "100vw" }}>
      <Resizable
        defaultSize={{
          width: "12%",
          height: "100%",
        }}
        style={{
          overflow: "auto",
          background: "#ddd",
        }}
        enable={{ right: true }}
      >
        <Paper
          style={{
            height: "80%",
            padding: "1em",
            backgroundColor: "black",
            color: "orange",
            marginLeft: "5%",
          }}
        >
          <img
            src={ads_logo}
            alt="Logo"
            style={{
              width: "100%",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          />
          <Typography variant="h4">Weblab</Typography>
          <List component={"nav"}>
            <ListItemButton
              selected={activeItem === "User Input"}
              onClick={() => handleMenuItemClick("User Input")}
              sx={{
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            >
              <ListItemText primary={"User Input"} />
            </ListItemButton>
            {
              <ListItemButton
                selected={activeItem === "Cohort Analysis"}
                onClick={() => handleMenuItemClick("Cohort Analysis")}
                sx={{
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              >
                <ListItemText primary={"Cohort Analysis"} />
              </ListItemButton>
            }
          </List>
        </Paper>
        <Paper
          style={{
            height: "20%",
            padding: "1em",
            backgroundColor: "black",
            color: "orange",
            marginLeft: "5%",
          }}
        >
          <SwitchTeamComponent
            onSwitch={handleSwitchTeam}
            currentUserName={currentUserName}
            defaultTeamId={defaultTeamId}
          />
          <TextField
            margin="dense"
            id="current_user"
            label="User"
            type="text"
            fullWidth
            variant="outlined"
            value={currentUserName}
            InputLabelProps={{
              sx: {
                color: "orange", // Set color for the label
                fontSize: "large",
              },
            }}
            InputProps={{
              readOnly: true,
              style: {
                color: "gray", // set font color to gray
              },
            }}
          />
        </Paper>
      </Resizable>
      <WeblabGridComponent
        isOpen={activeItem === "User Input"}
        currentUserName={currentUserName}
        currentTeamId={teamId}
        teamConfig={teamConfig}
      />
      <CohortAnalysisComponent
        isOpen={activeItem === "Cohort Analysis"}
        currentUserName={currentUserName}
        currentTeamId={teamId}
        teamConfig={teamConfig}
      />
    </div>
  );
};

export default MainPage;
