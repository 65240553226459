// NumberInput.tsx
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { numberFormatter } from "./formatters";

interface NumberInputProps {
  label?: string;
  initialValue?: number | null;
  onValueChange?: (value: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({
  label = "Number",
  initialValue = 0,
  onValueChange,
}) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(numberFormatter(initialValue));
  }, [initialValue]);

  const handleChange = (event: any) => {
    const { value } = event.target;
    const plainNumber = value.replace(/,/g, ""); // Remove existing commas for validation
    if (!isNaN(plainNumber) && plainNumber.trim() !== "") {
      setValue(numberFormatter(plainNumber)); // Format and set the value
      onValueChange && onValueChange(plainNumber);
    } else if (plainNumber === "") {
      setValue(""); // Allow clearing the input
      onValueChange && onValueChange(0);
    }
  };

  return (
    <TextField
      size="small"
      margin="dense"
      type="text"
      label={label}
      variant="outlined"
      value={value}
      onChange={handleChange}
      fullWidth
    />
  );
};

export default NumberInput;
