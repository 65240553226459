import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Configs, Metadata, TeamSettings } from "../models/API";
import {
  Divider,
  IconButton,
  TextField,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

interface TeamSettingsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (item: TeamSettings) => void;
  metaData: Metadata;
  configs: Configs;
  initialData: TeamSettings;
}

interface CustomColumn {
  label?: string;
  col_name?: string;
  type?: string;
}

interface FormErrors {
  id?: string;
  name?: string;
  columns: CustomColumn[];
  users: string[];
}

const TeamSettingsDialog: React.FC<TeamSettingsDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  metaData,
  configs,
  initialData,
}) => {
  const [newColumns, setNewColumns] = useState([
    // @ts-ignore
    initialData.custom_attributes.columns,
  ]);
  const [teamName, setTeamName] = useState(initialData.name);
  const [errors, setErrors] = useState<FormErrors>({ columns: [], users: [] });
  const [isWrapText, setIsWrapText] = React.useState(
    // @ts-ignore
    initialData.custom_attributes.is_wrap_text || false
  );
  const [isAutoHeight, setIsAutoHeight] = React.useState(
    // @ts-ignore
    initialData.custom_attributes.is_auto_height || false
  );
  const [isWrapHeaderText, setIsWrapHeaderText] = React.useState(
    // @ts-ignore
    initialData.custom_attributes.is_wrap_header_text || true
  );
  const [isAutoHeaderHeight, setIsAutoHeaderHeight] = React.useState(
    // @ts-ignore
    initialData.custom_attributes.is_auto_header_height || true
  );

  useEffect(() => {
    setTeamName(initialData.name);
    // @ts-ignore
    setNewColumns(initialData.custom_attributes.columns);
    // @ts-ignore
    setIsAutoHeight(initialData.custom_attributes.is_auto_height);
    // @ts-ignore
    setIsAutoHeaderHeight(initialData.custom_attributes.is_auto_header_height);
    // @ts-ignore
    setIsWrapText(initialData.custom_attributes.is_wrap_text);
    // @ts-ignore
    setIsWrapHeaderText(initialData.custom_attributes.is_wrap_header_text);
  }, [initialData, metaData, configs.usersDict]);

  if (!isOpen) {
    return null;
  }
  const handleAddNewColumn = () => {
    const updatedColumns = [...newColumns];
    setNewColumns([...updatedColumns, { label: "", type: "", col_name: "" }]);
  };

  const handleNewColumnChange = (index: number, value: string, key: string) => {
    const updatedColumns = [...newColumns];
    if (key === "col_name") {
      if (value.slice(0, 4) !== "col_") {
        value = "col_" + value; // prefix col_ to avoid conflict with non-customized columns
      }
      value = value.replace(/\s/g, "_");
    }
    updatedColumns[index][key] = value;
    setNewColumns(updatedColumns);
  };

  const validateForm = (teamSettings: TeamSettings) => {
    let counter: number = 0;
    let tempErrors: FormErrors = { columns: [], users: [] };
    if (!teamSettings.id) {
      tempErrors.id = "Team ID is required";
    }
    if (!teamSettings.name) {
      tempErrors.name = "Team name is required";
      counter = counter + 1;
    }
    // @ts-ignore
    teamSettings.custom_attributes.columns.forEach(
      (element: any, index: any) => {
        let customColumn: CustomColumn = { label: "", col_name: "", type: "" };
        tempErrors.columns.push(customColumn);
        if (!element.col_name) {
          tempErrors.columns[index].col_name = "ID is required";
          counter = counter + 1;
        }
        if (!element.label) {
          tempErrors.columns[index].label = "Label is required";
          counter = counter + 1;
        }
        if (!element.type) {
          tempErrors.columns[index].type = "Type is required";
          counter = counter + 1;
        }
      }
    );
    setErrors(tempErrors);
    return counter === 0; // Return true if no errors
  };

  const handleRemoveColumn = (e: any, index: any) => {
    const updatedColumns = [...newColumns];
    updatedColumns.splice(index, 1);
    setNewColumns(updatedColumns);
  };

  function modifyColumnState() {
    if (initialData.custom_attributes.hasOwnProperty("column_state")) {
      // @ts-ignore
      let columnState: JSON[] = initialData.custom_attributes.column_state;
      // remove deleted columns
      let newColumnState = columnState.filter(
        (element: any) =>
          element.colId.substring(0, 19) !== "additional_columns." ||
          newColumns.some(
            (filter) => filter.col_name === element.colId.substring(19)
          )
      );
      // add new columns
      let newAddedColumns = newColumns.filter(
        (element: any) =>
          !newColumnState.some(
            (filter) =>
              // @ts-ignore
              filter.colId === "additional_columns." + element.col_name
          )
      );
      newAddedColumns.forEach((item) => {
        const newColumnName = "additional_columns." + item.col_name;
        const element: any = { colId: newColumnName, width: 200, hide: false };
        newColumnState.splice(-3, 0, element); // keep favorite, delete, and comments at last elements
      });
      return newColumnState;
    }
  }

  const handleSave = async (e: React.FormEvent) => {
    const customAttributes: JSON = JSON.parse("{}");
    // @ts-ignore
    customAttributes.is_auto_height = isAutoHeight;
    // @ts-ignore
    customAttributes.is_auto_header_height = isAutoHeaderHeight;
    // @ts-ignore
    customAttributes.is_wrap_text = isWrapText;
    // @ts-ignore
    customAttributes.is_wrap_header_text = isWrapHeaderText;
    // @ts-ignore
    customAttributes.columns = newColumns;
    // @ts-ignore
    customAttributes.column_state = modifyColumnState();
    let teamSettings: TeamSettings = {
      id: initialData.id,
      name: teamName,
      custom_attributes: customAttributes,
    };
    if (validateForm(teamSettings)) {
      onSave(teamSettings);
      handleClose();
    }
  };
  const handleClose = () => {
    setTeamName(initialData.name);
    // @ts-ignore
    setNewColumns(initialData.custom_attributes.columns);
    // @ts-ignore
    setIsAutoHeight(initialData.custom_attributes.is_auto_height);
    // @ts-ignore
    setIsAutoHeaderHeight(initialData.custom_attributes.is_auto_header_height);
    // @ts-ignore
    setIsWrapText(initialData.custom_attributes.is_wrap_text);
    // @ts-ignore
    setIsWrapHeaderText(initialData.custom_attributes.is_wrap_header_text);
    setErrors({ columns: [], users: [] });
    onClose();
  };

  // @ts-ignore
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Team Settings</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tooltip title={"Read Only"} placement={"right-end"}>
              <TextField
                size="small"
                margin="dense"
                id="id"
                label="Team ID"
                type="number"
                variant="outlined"
                value={initialData.id}
                InputProps={{
                  readOnly: true,
                  style: {
                    color: "gray", // set font color to gray
                  },
                }}
              />
            </Tooltip>
            <TextField
              rows={1}
              size="medium"
              margin="dense"
              id="team_name"
              label="Team Name"
              type="text"
              fullWidth
              variant="outlined"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
        </Grid>
        <Divider style={{ margin: "20px 0" }} />
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={isWrapHeaderText}
                  onChange={(e) => setIsWrapHeaderText(e.target.checked)}
                  color="primary"
                />
              }
              label="Wrap Header Text"
              labelPlacement="end"
              sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={isAutoHeaderHeight}
                  onChange={(e) => setIsAutoHeaderHeight(e.target.checked)}
                  color="primary"
                />
              }
              label="Auto Header Height"
              labelPlacement="end"
              sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={isWrapText}
                  onChange={(e) => setIsWrapText(e.target.checked)}
                  color="primary"
                />
              }
              label="Wrap Cell Text"
              labelPlacement="end"
              sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  checked={isAutoHeight}
                  onChange={(e) => setIsAutoHeight(e.target.checked)}
                  color="primary"
                />
              }
              label="Auto Cell Height"
              labelPlacement="end"
              sx={{ marginLeft: "auto" }} // Pushes the checkbox to the right
            />
          </FormGroup>
        </FormControl>
        <Divider style={{ margin: "20px 0" }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {newColumns.map((element: any, index: any) => (
              <Box alignItems={"center"} key={index}>
                <Tooltip title={"Read Only"} placement={"bottom"}>
                  <TextField
                    size="small"
                    margin={"dense"}
                    label={"No"}
                    type={"text"}
                    value={index + 1}
                    InputProps={{
                      readOnly: true,
                      style: {
                        color: "gray", // set font color to gray
                        width: "80px",
                      },
                    }}
                  />
                </Tooltip>
                <TextField
                  size="small"
                  margin={"dense"}
                  label={"Column Label"}
                  type={"text"}
                  value={element.label}
                  onChange={(e) =>
                    handleNewColumnChange(index, e.target.value, "label")
                  }
                  InputProps={{
                    style: {
                      width: "250px",
                    },
                  }}
                  error={
                    errors.columns.length > index &&
                    !!errors.columns[index].label
                  }
                  helperText={
                    errors.columns.length <= index
                      ? ""
                      : errors.columns[index].label
                  }
                />
                <TextField
                  size="small"
                  margin={"dense"}
                  label={"Column ID"}
                  type={"text"}
                  value={element.col_name}
                  InputProps={{
                    style: {
                      width: "250px",
                    },
                  }}
                  onChange={(e) =>
                    handleNewColumnChange(index, e.target.value, "col_name")
                  }
                  error={
                    errors.columns.length > index &&
                    !!errors.columns[index].col_name
                  }
                  helperText={
                    errors.columns.length <= index
                      ? ""
                      : errors.columns[index].col_name
                  }
                />
                <FormControl
                  size="small"
                  margin="dense"
                  error={
                    errors.columns.length > index &&
                    !!errors.columns[index].type
                  }
                >
                  <InputLabel id="type-select-label">Column Type</InputLabel>
                  <Select
                    value={element.type}
                    label="Column Type"
                    sx={{ width: "150px" }}
                    onChange={(e) =>
                      handleNewColumnChange(index, e.target.value, "type")
                    }
                  >
                    <MenuItem key={0} value="string">
                      string
                    </MenuItem>
                    <MenuItem key={1} value="number">
                      number
                    </MenuItem>
                    <MenuItem key={2} value="boolean">
                      boolean
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.columns.length <= index
                      ? ""
                      : errors.columns[index].type}
                  </FormHelperText>
                </FormControl>
                <Tooltip title={"Remove Column"} placement={"right-end"}>
                  <IconButton
                    sx={{ width: 60, height: 60 }}
                    onClick={(e) => handleRemoveColumn(e, index)}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}
          </Grid>
        </Grid>
        <Tooltip title={"Add Column"} placement={"right-end"}>
          <IconButton
            size={"large"}
            onClick={handleAddNewColumn}
            color="primary"
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamSettingsDialog;
