import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Users, Team, CreateUserRequestInput } from "../models/API";
import { Grid } from "@mui/material";
import { generateClient } from "aws-amplify/api";
import { createUserRequest } from "../graphql/mutations";

interface RequestAccessDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (item: Users) => void;
  currentUserId: number;
  initialData: Team[];
}

const RequestAccessDialog: React.FC<RequestAccessDialogProps> = ({
  isOpen,
  onClose,
  onSave,
  currentUserId,
  initialData,
}) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [teams, setTeams] = useState<Team[] | null>([]);
  const client = generateClient();

  useEffect(() => {
    setTeams(initialData);
  }, [initialData]);

  if (!isOpen || !teams) {
    return null;
  }

  const handleCheckboxChange = (id: number) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    for (const element of selectedIds) {
      try {
        console.log("element=" + element + ", user_id = " + currentUserId);
        const input: CreateUserRequestInput = {
          user_id: currentUserId,
          team_id: element,
        };
        await client.graphql({
          query: createUserRequest,
          variables: {
            input: input,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
    handleClose();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Request Access</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {teams.map((team: Team, index: any) => (
              <div>
                <label>
                  <input
                    type={"checkbox"}
                    value={team.id}
                    checked={selectedIds.includes(team.id)}
                    onChange={() => handleCheckboxChange(team.id)}
                  />
                  - {team.name}
                </label>
                <br />
              </div>
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RequestAccessDialog;
