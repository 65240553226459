import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

interface ErrorModalProps {
  error: any;
  onClose: () => void;
}

export function getErrorMessage(error: any) {
  if (error && error.errors) return error.errors[0].message;
  if (error && error.message) return error.message;
  if (error) return error;
  return String("An unknown error occurred");
}

export function getErrorTitle(error: any) {
  if (error && error.errors) return error.errors[0].name;
  if (error && error.name) return error.name;
  return String("Error");
}

const ErrorModal: React.FC<ErrorModalProps> = ({ error, onClose }) => {
  const open = Boolean(error); // If there is an error, we open the modal
  const title = getErrorTitle(error);
  const message = getErrorMessage(error);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
