// CurrencyInput.tsx

import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { currencyFormatter } from "./formatters";

interface CurrencyInputProps {
  label?: string;
  currency?: string;
  locale?: string;
  initialValue?: number | null;
  onValueChange?: (value: number) => void;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  label = "Amount",
  currency = "USD",
  locale = "en-US",
  initialValue = 0,
  onValueChange,
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    setInputValue(currencyFormatter(initialValue, currency, locale));
  }, [inputValue, currency, locale, initialValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/[^0-9.-]+/g, ""); // Strip non-numeric characters
    const numeric = parseFloat(rawValue);

    if (!isNaN(numeric)) {
      setInputValue(currencyFormatter(numeric, currency, locale));
      onValueChange && onValueChange(numeric);
    } else {
      setInputValue("");
      onValueChange && onValueChange(0);
    }
  };

  return (
    <TextField
      size="small"
      margin="dense"
      label={label}
      value={inputValue}
      onChange={handleInputChange}
      variant="outlined"
      fullWidth
    />
  );
};

export default CurrencyInput;
