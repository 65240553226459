// formatters.ts
export function currencyFormatter(
  value: any,
  currency = "USD",
  locale = "en-US"
) {
  if (value === null || isNaN(value)) {
    return "";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}

export function numberFormatter(value: any, locale = "en-US") {
  if (value === null) {
    return "";
  }
  if (isNaN(value)) {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
}

export function covertUtcToLocal(utcTimestamp: string): string {
  const newIsoString = utcTimestamp.replace(" ", "T") + "Z";
  const date = new Date(newIsoString);
  return date.toLocaleString(undefined, { timeZoneName: "short" });
}

export function isValidTreatment(treatment: string): boolean {
  const regex = /^(C|T\d+)$/;
  return regex.test(treatment);
}
